<template>
     <GhmDataTable
          :dataList="dataList"
          :tableType="APP_DATATABLE_TYPE_DATA_LIST" >
          <template v-slot:addNewRowButton>
            {{ $t('admin.dbConnection.configMsSqlConnections') }}
          </template>
     </GhmDataTable>
</template>
<script>
import { mapActions } from 'vuex';
import Constants from '@/constants';
import GhmDataTable from '@/components/app/GhmDataTable.vue';

export default {
  data() {
    return {
      pageTitle: 'app.menu.dbConnections',
      dataList: [],
      APP_DATATABLE_TYPE_DATA_LIST: Constants.APP_DATATABLE_TYPE_DATA_LIST,
    };
  },

  components: {
    GhmDataTable,
  },

  mounted() {
    this.setPageTitleOfThisPage();

    // initialze list of all available datasource
    this.fetchAllDatasourceDetailsFromDB();
  },

  methods: {
    ...mapActions(['setPageTitle', 'findDBDatasourceDetailList']),

    setPageTitleOfThisPage() {
      console.log('--------------user queries page tityle METHOD setPageTitleOfThisPage ---------------');
      this.setPageTitle(this.pageTitle);
    },

    /**
     * Get all list of datasource details from database.
     */
    async fetchAllDatasourceDetailsFromDB() {
      console.log(
        '------------ START : fetching fetchAllDatasourceDetailsFromDB ********** ',
      );
      this.dataList = await this.findDBDatasourceDetailList();
      console.log(
        '------------ END : fetchAllDatasourceDetailsFromDB ********** ',
        this.dataList,
      );
    },
  },
};
</script>
